// src/components/Footer.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from 'react-icons/fa';

const socialIcons = [
  { icon: <FaFacebookF />, link: 'https://facebook.com' },
  { icon: <FaTwitter />, link: 'https://twitter.com' },
  { icon: <FaInstagram />, link: 'https://instagram.com' },
  { icon: <FaLinkedinIn />, link: 'https://linkedin.com' },
];

function Footer() {
  return (
    <footer className="bg-dark text-white py-4">
      <Container>
        <Row className="align-items-center">
          <Col md={6}>
            <p className="mb-0">&copy; {new Date().getFullYear()} Memoria. All rights reserved.</p>
          </Col>
          <Col md={6} className="text-md-right">
            {socialIcons.map((social, index) => (
              <a
                key={index}
                href={social.link}
                className="text-white ml-3"
                target="_blank"
                rel="noopener noreferrer"
              >
                {social.icon}
              </a>
            ))}
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
