// src/components/PrayerCardCreator.js
import React, { useState } from 'react';
import {
  TextField,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import VirtualPrayerCard from './VirtualPrayerCard';

const FormContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const PrayerCardCreator = () => {
  // State to manage form inputs
  const [formData, setFormData] = useState({
    name: '',
    dates: '',
    hometown: '',
    about: '',
    prayer: '',
    profileImage: '',
    backImage: '',
  });

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Optionally, handle image uploads in the future

  return (
    <Container maxWidth="lg" style={{ marginTop: '40px' }}>
      <Grid container spacing={4}>
        {/* Form Side */}
        <Grid item xs={12} md={6}>
          <FormContainer elevation={3}>
            <Typography variant="h5" gutterBottom>
              Create Your Prayer Card
            </Typography>
            <form noValidate autoComplete="off">
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Dates (e.g., 1950 - 2023)"
                name="dates"
                value={formData.dates}
                onChange={handleChange}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Hometown"
                name="hometown"
                value={formData.hometown}
                onChange={handleChange}
                margin="normal"
              />
              <TextField
                fullWidth
                label="About"
                name="about"
                value={formData.about}
                onChange={handleChange}
                margin="normal"
                multiline
                rows={4}
              />
              <TextField
                fullWidth
                label="Prayer"
                name="prayer"
                value={formData.prayer}
                onChange={handleChange}
                margin="normal"
                multiline
                rows={4}
              />
              <TextField
                fullWidth
                label="Profile Image URL"
                name="profileImage"
                value={formData.profileImage}
                onChange={handleChange}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Background Image URL"
                name="backImage"
                value={formData.backImage}
                onChange={handleChange}
                margin="normal"
              />
              {/* Future: Implement file upload functionality */}
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: '20px' }}
                onClick={() => alert('Save functionality not implemented yet.')}
              >
                Save Prayer Card
              </Button>
            </form>
          </FormContainer>
        </Grid>

        {/* Virtual Prayer Card Side */}
        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom>
            Preview
          </Typography>
          <VirtualPrayerCard data={formData} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default PrayerCardCreator;
