// src/components/VirtualPrayerCard.js
import React from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaShareAlt,
} from 'react-icons/fa';

const Card = styled(animated.div)`
  width: 300px;
  height: 500px;
  position: relative;
  transform-style: preserve-3d;
  transform-origin: center;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  margin: 0 auto;
`;

const CardSide = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding: 20px;
`;

// Front side
const Front = styled(CardSide)`
  background: linear-gradient(
    0deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 0, 255, 1) 0%,
    rgba(235, 243, 255, 1) 0%,
    rgba(210, 247, 255, 1) 100%
  );
  color: #000;
`;

const ProfileImage = styled.img`
  border-radius: 50%;
  width: 120px;
  height: 120px;
  object-fit: cover;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const ShareIcons = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
  color: #555;
`;

const IconWrapper = styled.div`
  cursor: pointer;
  transition: transform 0.2s, color 0.2s;

  &:hover {
    transform: translateY(-3px);
    color: #4a90e2;
  }
`;

// Back side
const Back = styled(CardSide)`
  background: url(${(props) => props.backgroundImage}) center/cover no-repeat;
  transform: rotateY(180deg);
  color: #fff;
  justify-content: center;
`;

const BackText = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  padding: 20px;
  border-radius: 10px;
`;

const VirtualPrayerCard = ({ data }) => {
  const [flipped, setFlipped] = React.useState(false);

  const { transform } = useSpring({
    transform: `rotateY(${flipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 75 },
  });

  return (
    <div onClick={() => setFlipped(!flipped)} style={{ cursor: 'pointer' }}>
      <Card style={{ transform }}>
        {/* Front Side */}
        <Front>
          <ShareIcons>
            <IconWrapper>
              <FaFacebook size={20} />
            </IconWrapper>
            <IconWrapper>
              <FaTwitter size={20} />
            </IconWrapper>
            <IconWrapper>
              <FaInstagram size={20} />
            </IconWrapper>
            <IconWrapper>
              <FaShareAlt size={20} />
            </IconWrapper>
          </ShareIcons>
          {data.profileImage && (
            <ProfileImage src={data.profileImage} alt={data.name} />
          )}
          <h4>{data.name || 'Name'}</h4>
          <p>{data.dates || 'Dates'}</p>
          <p>{data.hometown ? `From: ${data.hometown}` : 'Hometown'}</p>
          <p>{data.about || 'About the person...'}</p>
        </Front>
        {/* Back Side */}
        <Back backgroundImage={data.backImage}>
          <BackText>
            <p>{data.prayer || 'Your prayer goes here...'}</p>
          </BackText>
        </Back>
      </Card>
    </div>
  );
};

export default VirtualPrayerCard;
