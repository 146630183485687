// src/components/ProductHighlight.js
import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';
import InteractiveMemorialCard from './InteractiveMemorialCard';
import { Fade } from 'react-awesome-reveal'

const Section = styled.section`
  padding: 80px 0;
  background: linear-gradient(135deg, #f8fafc 0%, #e3e7ea 100%);
`;

const Heading = styled.h2`
  font-size: 2.8rem;
  text-align: center;
  margin-bottom: 50px;
  font-weight: 600;
  color: #2c3e50;
`;

const SubHeading = styled.h4`
  margin-top: 30px;
  font-weight: 600;
  color: #34495e;
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  font-size: 1.1rem;
  line-height: 1.8;
  color: #555;

  li {
    margin-bottom: 15px;
    position: relative;
    padding-left: 25px;

    &::before {
      content: '✔';
      position: absolute;
      left: 0;
      color: #27ae60;
      font-weight: bold;
    }
  }
`;

const StyledButton = styled(Button)`
  margin-top: 40px;
  background-color: #2980b9;
  border: none;
  padding: 12px 30px;
  font-size: 1.1rem;

  &:hover {
    background-color: #1f618d;
  }
`;

const CardContainer = styled.div`
  cursor: pointer;
  transition: transform 0.3s;
  display: inline-block;

  &:hover {
    transform: scale(1.05);
  }
`;

const ProductHighlight = () => {
  // State to control card flip
  const [flipped, setFlipped] = useState(false);

  // Toggle flip state
  const handleCardClick = () => {
    setFlipped(!flipped);
  };

  // Sample card data
  const cardsData = [
    {
      id: 1,
      name: 'Jane Doe',
      dates: '1950 - 2023',
      hometown: 'Chicago, Illinois',
      about:
        'Jane was a loving mother, wife, and friend. She enjoyed gardening, painting, and spending time with her grandchildren.',
      profileImage:
        'https://www.wsb.com/wp-content/uploads/2021/06/Matalin_Mary_PROMOPIC-scaled.jpg',
      prayer:
        'Our Father who art in heaven, hallowed be thy name...',
      backImage:
        'https://www.licatholicelementaryschools.org/wp-content/uploads/2024/03/F6os8f3W4AA7DpB.jpg',
    },
  ];

  return (
    <Section id="product">
      <Container>
      <Fade direction="up" triggerOnce>
          <Heading>Introducing Our Virtual Memorial Card</Heading>
        </Fade>

        <Row className="align-items-center">
          <Col md={6}>
            <Fade left cascade>
              <SubHeading>How It Works</SubHeading>
              <p>
                Our virtual memorial card is a customizable, digital tribute to
                celebrate your loved one's life. You can easily upload photos,
                videos, and share memories with family and friends across the
                globe.
              </p>
              <SubHeading>Key Features</SubHeading>
              <FeatureList>
                <li>Easy to create and customize</li>
                <li>Shareable with family and friends</li>
                <li>Secure and private access options</li>
                <li>Upload photos and videos</li>
                <li>Beautiful, personalized design templates</li>
              </FeatureList>
              <StyledButton size="lg">Create a Memorial Card</StyledButton>
            </Fade>
          </Col>
          <Col md={6} className="text-center">
            <Fade right>
              <CardContainer onClick={handleCardClick}>
                <InteractiveMemorialCard data={cardsData[0]} flipped={flipped} />
              </CardContainer>
            </Fade>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default ProductHighlight;
