// src/components/InteractiveMemorialCard.js
import React from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import { FaFacebook, FaTwitter, FaInstagram, FaShareAlt } from 'react-icons/fa';

// Styling the card container
const CardContainer = styled.div`
  perspective: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;

const Card = styled(animated.div)`
  width: 300px;
  height: 500px;
  position: relative;
  transform-style: preserve-3d;
  transform-origin: center;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
`;

const CardSide = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding: 20px;
`;

// Front side with a gradient background
const Front = styled(CardSide)`
  background: linear-gradient(
    0deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 0, 255, 1) 0%,
    rgba(235, 243, 255, 1) 0%,
    rgba(210, 247, 255, 1) 100%
  );
  color: #000;
  justify-content: flex-start;
`;

const ProfileImage = styled.img`
  border-radius: 50%;
  width: 120px;
  height: 120px;
  object-fit: cover;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const ShareIcons = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
  color: #555; /* Default icon color */
`;

// New styled component for icons
const IconWrapper = styled.div`
  cursor: pointer;
  transition: transform 0.2s, color 0.2s;

  &:hover {
    transform: translateY(-3px);
    color: #4a90e2; /* Change to your desired hover color */
  }
`;

// Back side with full background image and centered prayer text
const Back = styled(CardSide)`
  background: url(${(props) => props.backgroundImage}) center/cover no-repeat;
  transform: rotateY(180deg);
  color: #fff;
  justify-content: center;
`;

const BackText = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  padding: 20px;
  border-radius: 10px;
`;

const InteractiveMemorialCard = ({ data, flipped }) => {
  const { transform } = useSpring({
    transform: `rotateY(${flipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 75 },
  });

  return (
    <CardContainer>
      <Card style={{ transform }}>
        {/* Front Side */}
        <Front>
          <ShareIcons>
            <IconWrapper>
              <FaFacebook size={20} />
            </IconWrapper>
            <IconWrapper>
              <FaTwitter size={20} />
            </IconWrapper>
            <IconWrapper>
              <FaInstagram size={20} />
            </IconWrapper>
            <IconWrapper>
              <FaShareAlt size={20} />
            </IconWrapper>
          </ShareIcons>
          <ProfileImage src={data.profileImage} alt={data.name} />
          <h4>{data.name}</h4>
          <p>{data.dates}</p>
          <p>From: {data.hometown}</p>
          <p>{data.about}</p>
        </Front>
        {/* Back Side */}
        <Back backgroundImage={data.backImage}>
          <BackText>
            <p>{data.prayer}</p>
          </BackText>
        </Back>
      </Card>
    </CardContainer>
  );
};

export default InteractiveMemorialCard;
