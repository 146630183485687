import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import InteractiveMemorialCard from './InteractiveMemorialCard';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

// Sample data for memorial cards
const cardsData = [
  {
    id: 1,
    name: 'Jane Doe',
    dates: '1950 - 2023',
    hometown: 'Chicago, Illinois',
    about:
      'John was a loving father, husband, and friend. He enjoyed traveling, cooking, and spending time with his family.',
    profileImage:
      'https://www.wsb.com/wp-content/uploads/2021/06/Matalin_Mary_PROMOPIC-scaled.jpg',
    prayer: 'Our Father who art in heaven hallowed be thy name thy kingdom come thy will be done on earth as it is in heaven give us this day our daily bread and forgive us our trespasses as we forgive those who trespass against us and lead us not into temptation but deliver us from evil amen',
    backImage:
      'https://www.licatholicelementaryschools.org/wp-content/uploads/2024/03/F6os8f3W4AA7DpB.jpg',
  },
  {
    id: 2,
      name: 'Jane Doe 2',
      dates: '1950 - 2023',
      hometown: 'Chicago, Illinois',
      about:
        'John was a loving father, husband, and friend. He enjoyed traveling, cooking, and spending time with his family.',
      profileImage:
        'https://www.wsb.com/wp-content/uploads/2021/06/Matalin_Mary_PROMOPIC-scaled.jpg',
      prayer: 'Our Father who art in heaven hallowed be thy name thy kingdom come thy will be done on earth as it is in heaven give us this day our daily bread and forgive us our trespasses as we forgive those who trespass against us and lead us not into temptation but deliver us from evil amen',
      backImage:
        'https://www.licatholicelementaryschools.org/wp-content/uploads/2024/03/F6os8f3W4AA7DpB.jpg',
  },
  {
    id: 3,
    name: 'Jane Doe 3',
    dates: '1950 - 2023',
    hometown: 'Chicago, Illinois',
    about:
      'John was a loving father, husband, and friend. He enjoyed traveling, cooking, and spending time with his family.',
    profileImage:
      'https://www.wsb.com/wp-content/uploads/2021/06/Matalin_Mary_PROMOPIC-scaled.jpg',
    prayer: 'Our Father who art in heaven hallowed be thy name thy kingdom come thy will be done on earth as it is in heaven give us this day our daily bread and forgive us our trespasses as we forgive those who trespass against us and lead us not into temptation but deliver us from evil amen',
    backImage:
      'https://www.licatholicelementaryschools.org/wp-content/uploads/2024/03/F6os8f3W4AA7DpB.jpg',
  },
  // Add more sample cards as needed
];

// Styling the container
const Container = styled.div`
  width: 100%;
  min-height: 600px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledCard = styled(animated.div)`
  width: 300px;
  height: 500px;
  will-change: transform, opacity;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Navigation = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 40px;
`;

const ArrowButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 2.5rem;
  color: #000;
  &:disabled {
    color: #ccc;
    cursor: not-allowed;
  }
`;
const MemorialCardWallet = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [flipped, setFlipped] = useState(false);
  
    const [styles, api] = useSpring(() => ({
      x: 0,
      opacity: 1,
      config: { tension: 300, friction: 30 },
    }));
  
    useEffect(() => {
      console.log('Current Index:', currentIndex);
    }, [currentIndex]);
  
    const handleNext = () => {
      if (currentIndex < cardsData.length - 1) {
        setFlipped(false);
        api.start({
          x: -500,
          opacity: 0,
          onRest: () => {
            setCurrentIndex((prev) => prev + 1);
            api.set({ x: 500, opacity: 0 });
            api.start({ x: 0, opacity: 1 });
          },
        });
      }
    };
  
    const handlePrevious = () => {
      if (currentIndex > 0) {
        setFlipped(false);
        api.start({
          x: 500,
          opacity: 0,
          onRest: () => {
            setCurrentIndex((prev) => prev - 1);
            api.set({ x: -500, opacity: 0 });
            api.start({ x: 0, opacity: 1 });
          },
        });
      }
    };
  
    return (
      <Container>
        <StyledCard
          style={{
            transform: styles.x.to((x) => `translate3d(${x}px, 0, 0)`),
            opacity: styles.opacity,
          }}
          onClick={() => setFlipped(!flipped)}
        >
          <InteractiveMemorialCard data={cardsData[currentIndex]} flipped={flipped} />
        </StyledCard>
        <Navigation>
          <ArrowButton onClick={handlePrevious} disabled={currentIndex === 0}>
            <FaArrowLeft />
          </ArrowButton>
          <ArrowButton
            onClick={handleNext}
            disabled={currentIndex === cardsData.length - 1}
          >
            <FaArrowRight />
          </ArrowButton>
        </Navigation>
      </Container>
    );
  };
  
  export default MemorialCardWallet;