// src/components/Features.js
import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import {
  FaHandsHelping,
  FaShieldAlt,
  FaImage,
  FaGlobe,
  FaCloudUploadAlt,
  FaMobileAlt,
} from 'react-icons/fa';
import { Fade } from 'react-awesome-reveal';

// Styled Components
const Section = styled.section`
  padding: 80px 0;
  background-color: #f5f7fa;
`;

const FeatureCard = styled.div`
  background: #ffffff;
  border-radius: 12px;
  padding: 40px 30px;
  margin-bottom: 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  text-align: center;
  transition: transform 0.3s;
  min-height: 320px;

  &:hover {
    transform: translateY(-10px);
  }
`;

const IconWrapper = styled.div`
  font-size: 3rem;
  color: #4a90e2;
  margin-bottom: 20px;
`;

const FeatureTitle = styled.h4`
  font-size: 1.5rem;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 15px;
`;

const FeatureDescription = styled.p`
  font-size: 1rem;
  color: #7f8c8d;
`;

const Heading = styled.h2`
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 60px;
  color: #2c3e50;
`;

const CTAButton = styled.button`
  background-color: #4a90e2;
  border: none;
  color: #ffffff;
  font-weight: bold;
  margin-top: 20px;
  padding: 12px 30px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s;

  &:hover {
    background-color: #357ab8;
  }
`;

const featuresData = [
  {
    icon: <FaHandsHelping />,
    title: 'Bring Loved Ones Together',
    description:
      'Create a shared space where family and friends can contribute memories, stories, and tributes.',
  },
  {
    icon: <FaShieldAlt />,
    title: 'Privacy & Security',
    description:
      'Your memorials are protected with industry-leading security measures, ensuring peace of mind.',
  },
  {
    icon: <FaImage />,
    title: 'Multimedia Galleries',
    description:
      'Upload photos and videos to celebrate the life and legacy of your loved one in vivid detail.',
  },
  {
    icon: <FaGlobe />,
    title: 'Accessible Worldwide',
    description:
      'Friends and family can access the memorial from anywhere, fostering global connections.',
  },
  {
    icon: <FaCloudUploadAlt />,
    title: 'Easy to Use',
    description:
      'Our intuitive platform makes it simple to create and manage memorials without any technical skills.',
  },
  {
    icon: <FaMobileAlt />,
    title: 'Mobile Friendly',
    description:
      'Enjoy a seamless experience on any device, so you can connect whenever and wherever you are.',
  },
];

function Features() {
  return (
    <Section id="features">
      <Container>
        <Fade direction="up" triggerOnce>
          <Heading>Celebrate Life's Precious Moments</Heading>
        </Fade>
        <Row>
          {featuresData.map((feature, index) => (
            <Col md={4} key={index}>
              <Fade direction="up" delay={index * 100} triggerOnce>
                <FeatureCard>
                  <IconWrapper>{feature.icon}</IconWrapper>
                  <FeatureTitle>{feature.title}</FeatureTitle>
                  <FeatureDescription>{feature.description}</FeatureDescription>
                </FeatureCard>
              </Fade>
            </Col>
          ))}
        </Row>
        <div className="text-center mt-5">
          <CTAButton>Create a Memorial Today</CTAButton>
        </div>
      </Container>
    </Section>
  );
}

export default Features;
