// src/components/Testimonials.js
import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { FaStar } from 'react-icons/fa';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const TestimonialSection = styled.section`
  background-color: #f5f7fa;
  padding: 80px 0;
`;

const Heading = styled.h2`
  font-size: 3rem; /* Increased font size */
  text-align: center;
  margin-bottom: 60px;
  color: #2c3e50;
`;

const TestimonialCard = styled.div`
  background: #ffffff;
  border-radius: 12px;
  padding: 60px 40px; /* Increased padding */
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  text-align: center;
  align-items: center;
  margin: 0 auto; /* Center the card */
  max-width: 700px; /* Adjusted width */
`;

const Quote = styled.p`
  font-size: 1.5rem; /* Increased font size */
  color: #555;
  margin-bottom: 40px;
  position: relative;
  padding: 0 40px; /* Increased padding */

  &::before {
    content: '“';
    font-size: 4rem; /* Increased font size */
    color: #4a90e2;
    position: absolute;
    left: 10px;
    top: -20px;
  }

  &::after {
    content: '”';
    font-size: 4rem; /* Increased font size */
    color: #4a90e2;
    position: absolute;
    right: 10px;
    bottom: -30px;
  }
`;

const Author = styled.p`
  font-size: 1.2rem; /* Increased font size */
  font-weight: bold;
  color: #2c3e50;
`;

const Avatar = styled.img`
  display: block; /* Add this line */
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto 30px; /* Center the image and add bottom margin */
  object-fit: cover;
`;


const StarRating = styled.div`
  color: #f1c40f;
  margin-bottom: 30px; /* Increased margin */

  svg {
    margin: 0 4px; /* Adjusted spacing */
    font-size: 1.5rem; /* Increased icon size */
  }
`;

const SliderWrapper = styled.div`
  max-width: 900px; /* Increased max-width */
  margin: 0 auto;
`;

const testimonialsData = [
  {
    quote:
      'Memoria allowed us to create a beautiful tribute that brought our family together.',
    author: 'Sarah L.',
    avatar: 'https://randomuser.me/api/portraits/women/44.jpg',
    rating: 5,
  },
  {
    quote:
      "An incredible platform that made it easy to honor my grandfather's life.",
    author: 'David R.',
    avatar: 'https://randomuser.me/api/portraits/men/35.jpg',
    rating: 5,
  },
  {
    quote:
      'The customization options let us celebrate our mother in a unique way.',
    author: 'Emily K.',
    avatar: 'https://randomuser.me/api/portraits/women/61.jpg',
    rating: 5,
  },
  {
    quote:
      'Memoria allowed us to create a beautiful tribute that brought our family together.',
    author: 'Sarah L.',
    avatar: 'https://randomuser.me/api/portraits/women/42.jpg',
    rating: 5,
  },
  {
    quote:
      "An incredible platform that made it easy to honor my grandfather's life.",
    author: 'David R.',
    avatar: 'https://randomuser.me/api/portraits/men/32.jpg',
    rating: 5,
  },
  {
    quote:
      'The customization options let us celebrate our mother in a unique way.',
    author: 'Emily K.',
    avatar: 'https://randomuser.me/api/portraits/women/63.jpg',
    rating: 5,
  },
  {
    quote:
      'Memoria allowed us to create a beautiful tribute that brought our family together.',
    author: 'Sarah L.',
    avatar: 'https://randomuser.me/api/portraits/women/43.jpg',
    rating: 5,
  },
  {
    quote:
      "An incredible platform that made it easy to honor my grandfather's life.",
    author: 'David R.',
    avatar: 'https://randomuser.me/api/portraits/men/25.jpg',
    rating: 5,
  },
  {
    quote:
      'The customization options let us celebrate our mother in a unique way.',
    author: 'Emily K.',
    avatar: 'https://randomuser.me/api/portraits/women/28.jpg',
    rating: 5,
  },
];

function Testimonials() {
  const settings = {
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000, /* Increased autoplay speed */
    infinite: true,
    speed: 800, /* Adjusted transition speed */
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <TestimonialSection id="testimonials">
      <Heading>What Our Users Say</Heading>
      <SliderWrapper>
        <Slider {...settings}>
          {testimonialsData.map((testimonial, index) => (
            <div key={index}>
              <TestimonialCard>
                <Avatar src={testimonial.avatar} alt={testimonial.author} />
                <StarRating>
                  {Array.from({ length: testimonial.rating }, (_, i) => (
                    <FaStar key={i} />
                  ))}
                </StarRating>
                <Quote>{testimonial.quote}</Quote>
                <Author>{testimonial.author}</Author>
              </TestimonialCard>
            </div>
          ))}
        </Slider>
      </SliderWrapper>
    </TestimonialSection>
  );
}

export default Testimonials;
