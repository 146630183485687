// src/App.js
import React from 'react';
import Navbar from './components/Navbar';
import ProductHighlight from './components/ProductHighlight';
import Testimonials from './components/Testimonials';
import CTA from './components/CTA';
import Footer from './components/Footer';
import Features from './components/Features';
import MemorialCardWallet from './components/MemorialCardWallet';
import PrayerCardCreator from './components/PrayerCardCreator';

function App() {
  
  return (
    <>
      <Navbar />
      <ProductHighlight />
      <Features />
      <Testimonials />
      <PrayerCardCreator />
      <MemorialCardWallet />
      <CTA />
      <Footer />
    </>
  );
}

export default App;
